/** @jsx jsx */
import Layout from '../layouts/Layout';
import { jsx } from '@emotion/core';
import { ElementList } from '@nimles/react-web-components';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserPosition } from '../redux/geo';
import { PageModel } from '@nimles/models/lib/cms';
import { SiteMetadata } from '../types';
import { getImageUrl } from '../utils';
import { gql, useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { ApolloProvider } from '@apollo/react-hooks';
import { OrganizationMap } from '../components/organization/map/OrganizationMap';
import { graphql, useStaticQuery } from 'gatsby';
import { OrganizationModel } from '@nimles/models';

export const client: any = new ApolloClient({
  uri: 'https://api.nimles.com/cms/public/graphql',
  fetch,
});

const pageQuery = gql`
  query GetPage($tenantId: Uuid!, $id: Uuid!) {
    page(tenantId: $tenantId, id: $id, draft: true) {
      id
      organizationId
      pageType
      title
      titleLocalized
      uniqueName
      elements {
        id
        type
        uniqueName
        content
        contentLocalized
        contentId
        properties
        children {
          id
          type
          uniqueName
          content
          contentLocalized
          contentId
          properties
          children {
            id
            type
            uniqueName
            content
            contentLocalized
            contentId
            properties
            children {
              id
              type
              uniqueName
              content
              contentLocalized
              contentId
              properties
              children {
                id
                type
                uniqueName
                content
                contentLocalized
                contentId
                properties
                children {
                  id
                  type
                  uniqueName
                  content
                  contentLocalized
                  contentId
                  properties
                  children {
                    id
                    type
                    uniqueName
                    content
                    contentLocalized
                    contentId
                    properties
                    children {
                      id
                      type
                      uniqueName
                      content
                      contentLocalized
                      contentId
                      properties
                      children {
                        id
                        type
                        uniqueName
                        content
                        contentLocalized
                        contentId
                        properties
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Page = ({ pageContext, location }) => {
  const dispatch = useDispatch();
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  let {
    page,
    site,
  }: {
    page: PageModel;
    site: { siteMetadata: SiteMetadata };
  } = pageContext;

  const siteMetadata: SiteMetadata = site.siteMetadata;

  const { data } = useQuery<{ page: PageModel }>(pageQuery, {
    variables: { tenantId: siteMetadata.tenantId, id: page?.id },
    skip: !draft,
  });

  const { language } = useContext(I18nextContext);

  if (data?.page) {
    page = data.page || page;
  }

  useEffect(() => {
    navigator.permissions
      ?.query({
        name: 'geolocation',
      })
      .then(
        (permission) =>
          permission.state === 'granted' && dispatch(requestUserPosition())
      );
  }, []);

  const meta = {
    title: page?.title,
    description: page?.summary,
    image: getImageUrl(page?.imageId),
  };

  const PageComponent = ({ page }: { page: PageModel }) => {
    const data = useStaticQuery(graphql`
      query OrganizationsQuery {
        organizationManagement {
          organizations {
            edges {
              node {
                id
                name
                description
                uniqueName
                phoneNumber
                website
                bannerId
                address {
                  id
                  street
                  postalCode
                  city
                  state
                }
                location {
                  id
                  latitude
                  longitude
                }
                socialLinks
                tags
              }
            }
          }
        }
      }
    `);
    if (!page) {
      return null;
    }

    const organizations: OrganizationModel[] = data.organizationManagement.organizations.edges.map(
      ({ node }) => node
    );

    switch (page.pageType) {
      case 'Map':
        return <OrganizationMap organizations={organizations} />;

      default:
        return null;
    }
  };

  return (
    <Layout
      header
      footer={page?.pageType !== 'Map'}
      meta={meta}
      location={location}
      siteMetadata={siteMetadata}
    >
      <ElementList
        tenantId={siteMetadata.tenantId}
        elements={page?.elements}
        location={location}
        culture={language}
      />
      <PageComponent page={page} />
    </Layout>
  );
};

const PageTemplate = (props) => (
  <ApolloProvider client={client}>
    <Page {...props} />
  </ApolloProvider>
);

export default PageTemplate;
