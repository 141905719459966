import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { MapInfoWindow } from '../../map/MapInfoWindow';
import { MapMarker } from '../../map/MapMarker';
import adbildelar from '../../../images/carware/adbildelar-marker.png';
import autoexperten from '../../../images/carware/autoexperten-marker.png';
import bds from '../../../images/carware/bds-marker.png';
import bosch from '../../../images/carware/bosch-marker.png';
import meca from '../../../images/carware/meca-marker.png';
import mekonomen from '../../../images/carware/mekonomen-marker.png';
import mekopartner from '../../../images/carware/mekopartner-marker.png';
import marker from '../../../images/carware/marker.svg';

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  margin-bottom: 5px;

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const OrganizationMarker = ({
  organization,
  location,
  isSelected,
  onSelect,
}) => {
  const {
    name,
    uniqueName,
    description,
    address: { street, postalCode, city },
    tags,
  } = organization;
  return (
    <MapMarker
      location={location}
      onClick={() => onSelect(isSelected ? null : organization)}
      icon={
        tags?.includes('ADBildelar')
          ? adbildelar
          : tags?.includes('Autoexperten')
          ? autoexperten
          : tags?.includes('BDS')
          ? bds
          : tags?.includes('Bosch Car Service')
          ? bosch
          : tags?.includes('Meca')
          ? meca
          : tags?.includes('Mekonomen')
          ? mekonomen
          : tags?.includes('Mekopartner')
          ? mekopartner
          : marker
      }
    >
      {isSelected ? (
        <MapInfoWindow onClose={() => onSelect(null)}>
          <div>
            <div>
              <Link to={`/${uniqueName}`}>
                <strong>{name}</strong>
              </Link>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div>
              {street}, {postalCode} {city}
            </div>
          </div>
        </MapInfoWindow>
      ) : null}
    </MapMarker>
  );
};
