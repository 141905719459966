/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { distance, Map, toLatLng } from '../../map/Map';
import { Marker } from '@react-google-maps/api';
import { List, ListItem } from '@nimles/react-web-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCompass } from '@fortawesome/free-solid-svg-icons';
import { OrganizationItem } from '../../organization/listitem/OrganizationListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, GeoState } from '../../../redux/types';
import { requestUserPosition } from '../../../redux/geo';
import { Overlay } from '../../Overlay';
import {
  CategoryModel,
  LocationModel,
  OrganizationModel,
} from '@nimles/models';
import { OrganizationMarker } from '../../organization/marker/OrganizationMarker';
import { Search } from '../../Search';
import { compareBy } from '../../../utils';

const mapStyle = {
  width: '100%',
  flex: 1,
};

const MapBackground = styled.section`
  height: 100vh;
  background-color: ${({ theme }) => theme.header.background};
`;

const MapSection = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 80px 0 0;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    flex-direction: row;
  }
`;

const MapContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 0;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    order: 1;
  }
`;

const ASide = styled.aside`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface.color};
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  order: 1;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    order: 0;
    flex: 0 0 500px;
  }
`;

const SearchContainer = styled.div``;

const SelectedCategories = styled.div``;

const SelectedCategory = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.surface.onColor};
  padding: 2px 8px;
  margin: 4px;
  border-radius: 4px;
  display: inline-block;

  svg {
    margin-left: 6px;
  }
`;

const OrganizationList = styled(List)`
  flex: 1;
  overflow-y: auto;
`;

const insideBounds = (organization: OrganizationModel, bounds) =>
  !bounds ||
  (organization.location &&
    organization.location.latitude > bounds.southWest.latitude &&
    organization.location.latitude < bounds.northEast.latitude &&
    organization.location.longitude > bounds.southWest.longitude &&
    organization.location.longitude < bounds.northEast.longitude);

interface Props {
  organizations: OrganizationModel[];
  categories?: CategoryModel[];
  query?: string;
  regions?: string[];
  selectedRegion?: string;
  selectedCategories?: CategoryModel[];
  onQueryChange?: (query: string) => void;
  onRegionChange?: (query: string) => void;
  onOrganizationSelect?: (organization: OrganizationModel) => void;
  onCategoriesSelect?: (categories: CategoryModel[]) => void;
}

const OrganizationMapComponent = ({
  organizations: sourceOrganizations,
  categories,
  query,
  regions,
  selectedRegion,
  selectedCategories = [],
  onQueryChange,
  onRegionChange,
  onOrganizationSelect,
  onCategoriesSelect,
}: Props) => {
  const dispatch = useDispatch();

  const geo = useSelector<RootState, GeoState>(({ geo }) => geo);

  const [userPosition, setUserPosition] = useState<LocationModel>();
  const [bounds, setBounds] = useState<{
    northEast: LocationModel;
    southWest: LocationModel;
  }>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<any>();
  const [resultLimit, setResultLimit] = useState(20);
  const [isLoadingPosition, setLoadingPosition] = useState(false);
  const [organizationsInsideBounds, setOrganizationsInsideBounds] = useState<
    any[]
  >([]);
  const [organizationsOutsideBounds, setOrganizationsOutsideBounds] = useState<
    any[]
  >([]);

  const requestPostion = async () => {
    try {
      setLoadingPosition(true);
      await dispatch(requestUserPosition());
      setLoadingPosition(false);
    } catch (error) {
      setLoadingPosition(false);
      console.warn(error);
    }
  };

  const organizations = useMemo(
    () =>
      sourceOrganizations
        .map((org) => ({
          ...org,
          distance:
            userPosition && org.location
              ? distance(userPosition, org.location, 'K')
              : null,
        }))
        .sort(compareBy('distance', 'name')),
    [sourceOrganizations, userPosition]
  );

  useEffect(() => {
    requestPostion();
  }, []);

  useEffect(() => {
    setUserPosition(geo.userPosition);
  }, []);

  const handleSubmit = ({
    query,
    position,
    organization,
    categories,
    region,
  }: {
    query?: string;
    position?: {
      latitude: number;
      longitude: number;
    };
    region?: string;
    category?: CategoryModel;
    categories?: CategoryModel[];
    organization?: OrganizationModel;
  }) => {
    if (query) {
      onQueryChange(query);
    } else if (position) {
      setUserPosition(position);
    } else if (organization) {
      onOrganizationSelect(organization);
    } else if (categories) {
      onCategoriesSelect(categories);
    } else if (region) {
      onRegionChange(region);
    }
  };

  const handleMapChange = (bounds) => {
    setResultLimit(20);
    setBounds(bounds);
  };

  useEffect(() => {
    setResultLimit(20);
    const [organizationsInside, organizationsOutside] = organizations.reduce(
      ([inside, outside], organization) =>
        insideBounds(organization, bounds)
          ? [[...inside, organization], outside]
          : [inside, [...outside, organization]],
      [[], []]
    );

    setOrganizationsInsideBounds(organizationsInside);
    setOrganizationsOutsideBounds(organizationsOutside);
  }, [organizations, bounds]);

  const locations = useMemo(
    () =>
      organizations
        .filter((organization) => organization.location)
        .map((organization) => ({
          ...organization.location,
          organization,
        })),
    [organizations]
  );

  const dataLength = Math.min(organizationsInsideBounds.length, resultLimit);

  const hasMore = organizationsInsideBounds.length > resultLimit;

  const outsideResultLimit = resultLimit - organizationsInsideBounds.length;

  return locations ? (
    <>
      {isLoadingPosition && !userPosition ? (
        <Overlay>
          <FontAwesomeIcon spin icon={faCompass} size="2x" />
          Vi begär din position för att kunna visa tjänster nära dig
        </Overlay>
      ) : null}
      <MapBackground>
        <MapSection>
          <ASide>
            <SearchContainer>
              <Search
                categories={categories}
                regions={regions}
                selectedCategories={selectedCategories}
                onSubmit={handleSubmit}
                query={query}
                onQuery={onQueryChange}
                organizations={organizations}
              />
            </SearchContainer>
            <SelectedCategories>
              {selectedRegion ? (
                <SelectedCategory onClick={() => onRegionChange(null)}>
                  {selectedRegion}
                  <FontAwesomeIcon icon={faTimes} />
                </SelectedCategory>
              ) : null}
              {selectedCategories.map(({ id, name }) => (
                <SelectedCategory
                  key={id}
                  onClick={() =>
                    onCategoriesSelect(
                      selectedCategories.filter(
                        ({ id: prevId }) => id !== prevId
                      )
                    )
                  }
                >
                  {name}
                  <FontAwesomeIcon icon={faTimes} />
                </SelectedCategory>
              ))}
            </SelectedCategories>
            <OrganizationList id="scrollable">
              <InfiniteScroll
                dataLength={dataLength}
                next={() => setResultLimit((prev) => prev + 20)}
                hasMore={hasMore}
                loader={<h4>Laddar...</h4>}
                scrollableTarget="scrollable"
              >
                {organizationsInsideBounds
                  .slice(0, resultLimit)
                  .map((organization) => (
                    <OrganizationItem
                      key={organization.id}
                      organization={organization}
                      isSelected={organization.id === selectedOrganization?.id}
                      onSelect={(organization) => {
                        setSelectedOrganization(organization);
                        // navigate('/' + organization.uniqueName);
                      }}
                    />
                  ))}
                {resultLimit - organizationsInsideBounds.length ? (
                  <ListItem>Utanför kartan</ListItem>
                ) : null}
                {organizationsOutsideBounds
                  .slice(0, outsideResultLimit < 0 ? 0 : outsideResultLimit)
                  .map((organization) => (
                    <OrganizationItem
                      key={organization.id}
                      organization={organization}
                      isSelected={organization.id === selectedOrganization?.id}
                      onSelect={(organization) => {
                        setSelectedOrganization(organization);
                        // navigate('/' + organization.uniqueName);
                      }}
                    />
                  ))}
              </InfiniteScroll>
            </OrganizationList>
          </ASide>
          <MapContainer>
            <Map
              locations={locations}
              mapStyle={mapStyle}
              center={userPosition}
              zoom={userPosition ? 11 : null}
              onChange={handleMapChange}
              fitLocations={!userPosition || !!selectedRegion}
            >
              {userPosition ? (
                <Marker position={toLatLng(userPosition)} />
              ) : null}
              {locations.length > 0
                ? locations.map((location, index) => (
                    <OrganizationMarker
                      key={index}
                      organization={location.organization}
                      location={location}
                      isSelected={
                        location.organization?.id === selectedOrganization?.id
                      }
                      onSelect={setSelectedOrganization}
                    />
                  ))
                : null}
            </Map>
          </MapContainer>
        </MapSection>
      </MapBackground>
    </>
  ) : null;
};

export const OrganizationMap = memo(OrganizationMapComponent);
