import React, { FC } from 'react';
import { Column, Row, ListItem } from '@nimles/react-web-components';
import placeholder from '../images/placeholder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { rgba } from 'polished';
import adbildelar from '../../../images/carware/adbildelar.png';
import autoexperten from '../../../images/carware/autoexperten.png';
import bds from '../../../images/carware/bds.png';
import bosch from '../../../images/carware/bosch.png';
import meca from '../../../images/carware/meca.png';
import mekonomen from '../../../images/carware/mekonomen.png';
import mekopartner from '../../../images/carware/mekopartner.png';
import { NavButton } from '@nimles/react-web-components-redux';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';

const OrganizationListItem = styled(ListItem)`
  color: ${({ theme }) => theme.colors.surface.onColor};
  background-color: ${({ active, theme }) =>
    active ? rgba(theme.colors.primary.color, 0.1) : 'unset'};
  display: flex;
  cursor: pointer;
  border-left: 4px solid
    ${({ active, theme }) =>
      active ? theme.colors.primary.color : 'transparent'};
  border-bottom: 1px solid #ccc;
  padding: 5px;
`;

const OrganizationRow = styled(Row)`
  margin: 0 -5px;
`;

const ImageColumn = styled(Column)`
  padding: 0 5px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 10px;
`;

const TextColumn = styled(Column)`
  padding: 0 5px;
`;

const Description = styled.div<{ isOpen: boolean }>`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '41px')};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.h4`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`;

const Distance = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

interface Props {
  organization;
  onSelect;
  isSelected;
}

const OrganizationComponent: FC<Props> = ({
  organization,
  onSelect,
  isSelected,
}) => {
  const {
    name,
    uniqueName,
    description,
    bannerId,
    address: { street, postalCode, city },
    distance,
    tags,
  } = organization;

  return (
    <OrganizationListItem
      active={isSelected}
      onClick={() => onSelect(isSelected ? null : organization)}
    >
      <OrganizationRow>
        <ImageColumn>
          <Image
            src={
              bannerId
                ? getImageUrl(bannerId) + '?width=480'
                : tags?.includes('ADBildelar')
                ? adbildelar
                : tags?.includes('Autoexperten')
                ? autoexperten
                : tags?.includes('BDS')
                ? bds
                : tags?.includes('Bosch Car Service')
                ? bosch
                : tags?.includes('Meca')
                ? meca
                : tags?.includes('Mekonomen')
                ? mekonomen
                : tags?.includes('Mekopartner')
                ? mekopartner
                : null
            }
          />
        </ImageColumn>
        <TextColumn flex>
          <Name>
            <div>{name}</div>
            {distance && <Distance>{parseInt(distance)} km</Distance>}
          </Name>
          <div>{street}</div>
          <div>
            {postalCode} {city}
          </div>
          <Description
            dangerouslySetInnerHTML={{ __html: description }}
            isOpen={isSelected}
          />
          {isSelected ? (
            <Row justify="flex-end">
              <Column>
                <NavButton primary to={'/' + uniqueName}>
                  Till verkstaden <FontAwesomeIcon icon={faChevronRight} />
                </NavButton>
              </Column>
            </Row>
          ) : null}
        </TextColumn>
      </OrganizationRow>
    </OrganizationListItem>
  );
};
export const OrganizationItem = React.memo(OrganizationComponent);
